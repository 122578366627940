.ground-transportation-component {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    position: relative;
}

.ground-transportation-component .ground-transportation-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ground-transportation-component .ground-transportation-header-section .ground-transportation-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.ground-transportation-component .ground-transportation-content{
    display: grid;
    grid-template-columns: calc(40% - 10px) calc(60% - 10px);
    width: 100%;
    padding: 0px 0px 10px 0px;
    gap: 20px;
}

.ground-transportation-component .transport-form-container,
.ground-transportation-component .map-container{
    padding: 20px;
    background: var(--color-4);
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
    border-top: 2px solid var(--color-1);
}

.ground-transportation-component .map-container .map-img{
    width: 100%;
    object-fit: cover;
}

.transport-form-container .mui-form-control{
    padding: 20px 0px;
}

.transport-form-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.transport-form-container .mui-form-control .MuiInputLabel-root{
    color: var(--text-color);
}

.transport-form-container .mui-form-control .MuiInput-underline:after{
    border-color: var(--text-color);
}

.transport-form-container .mui-form-control button.submit-btn {
    width: 100%;
    display: block;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-dark-text-color);
    background: var(--color-2);
    border: 0px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.transport-form-container .mui-form-control button.submit-btn:hover{
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    transform: translateY(-5px);
}

@media only screen and (max-width: 900px) {
    .ground-transportation-component .ground-transportation-content{
        grid-template-columns: auto;
    }
    
}