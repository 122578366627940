.user-dashboard-component {
    width: 100%;
    padding: 10px 20px;
    position: relative;
}

.user-dashboard-component .user-dashboard-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-dashboard-component .user-dashboard-header-section .user-dashboard-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.user-dashboard-container {
    margin: 0;
    padding: 0;
}

.user-dashboard-container .user-dashboard-content {
    padding: 15px;
    margin-top: 10px;
    width: 100%;
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--text-color);
    position: relative;
    border-top: 2px solid var(--color-1);
}

.user-dashboard-content .trip-images-list {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-gap: 20px; */
}

.user-dashboard-content .trip-images-list .each-trip-img {
    border: 2px solid var(--bg-dark-text-color);
    background: var(--color-2);
    display: grid;
    padding: 10px;
    margin: 5px;
    width: calc(100% - 10px);
}

.user-dashboard-content .trip-images-list .each-trip-img:last-child {
    margin-right: 0px;
}

.trip-images-list .each-trip-img img {
    width: 100%;
    height: auto;
    border: 2px solid var(--bg-dark-text-color);
}

.trip-images-list .each-trip-img h3 {
    text-align: center;
    color: var(--bg-dark-text-color);
    margin: 5px;
}

.trips-data-container {
    width: calc(100% - 10px);
    margin: 20px 5px 5px 5px;
}

.trips-data-table-container {
    width: 100%;
    overflow: auto;
}

.trips-data-table-container table {
    width: 100%;
    border-collapse: collapse;
}

.trips-data-table-container table th {
    text-align: center;
    border: 2px solid var(--bg-dark-text-color);
    font-weight: bold;
    background: var(--color-3);
    color: var(--bg-dark-text-color);
    padding: 10px;
}

.trips-data-table-container table td {
    border: 2px solid var(--bg-dark-text-color);
    padding: 10px;
}

@media only screen and (max-width: 800px) {
    .user-dashboard-content .trip-images-list {
        grid-template-columns: 50% 50%;
    }
}