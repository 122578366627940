
.navbar-container {
    width: 100%;
    height: var(--navbar-height);
    border-bottom: 2px solid var(--color-1);
    position: sticky;
    z-index: 1000;
    box-shadow: 0px 10px 30px rgb(0 0 0 / 20%);
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-4);
}

.navbar-container .landing-page-nav {
    height: calc(100% - 2px);
    background: var(--color-4);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 20px;
}

.navbar-container .logo-container {
    height: var(--navbar-height);
    padding: 10px 20px;
}

.navbar-container .logo-container img{
    height: 100%;
}

.navbar-container .landing-page-nav ul.landing-page-nav-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
}

.landing-page-nav ul.landing-page-nav-items li>* {
    padding: 5px 10px;
    margin: 5px;
}

ul.landing-page-nav-items li>a {
    text-decoration: none;
    color: var(--text-color);
    transition: all 0.2s ease-in;
    border: 2px solid var(--color-4);
    position: relative;
    display: flex;
    align-items: center;
}

ul.landing-page-nav-items li>a>svg{
    margin-right: 5px;
    color: var(--color-1);
}

ul.landing-page-nav-items li>a:hover {
    color: var(--color-1);
    transform: scale(1.1);
}

ul.landing-page-nav-items li>a:hover:after {
    position: absolute;
    content: "";
    margin: 0px auto;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 45%;
    height: 2px;
    background: var(--color-1);
}


ul.landing-page-nav-items li>button {
    cursor: pointer;
    color: var(--color-1);
    background: var(--color-4);
    border: 2px solid var(--color-1);
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}

ul.landing-page-nav-items li>button:hover {
    background: var(--color-1);
    color: var(--bg-dark-text-color);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
}

.navbar-container .profile-menu-icon{
    padding: 0px 20px;
    color: var(--color-1);
}


@media only screen and (max-width: 600px) {
    .navbar-container .landing-page-nav ul.landing-page-nav-items{
        display: none;
    }
    
}