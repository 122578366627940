.arrival-form-container {
    width: 100%;
    display: flow-root;
}

.arrival-form-container .form-header{
    margin: 10px 0px;
    font-size: 20px;
    font-weight: bolder;
    display: flow-root;
    padding: 10px;
    background: var(--color-3);
    border-radius: 4px;
}

.arrival-form-container .form-header p{
    margin: 0px;
    color: var(--bg-dark-text-color)
}

.arrival-form-container .two-col-grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.arrival-form-container .three-col-grid {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.arrival-form-container .form-section{
    border: 2px solid var(--color-1);
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
    margin: 20px 0px 20px 0px;
    border-radius: 4px;
    padding: 10px;
    transition: all 1s ease;
}

.arrival-form-container .form-section .section-header{
    background: var(--color-2);
    width: 100%;
    display: flow-root;
    border-radius: 4px;
    cursor: pointer;
}

.section-header p {
    margin: 10px;
    color: var(--bg-dark-text-color);
    font-weight: 600;
    font-size: 18px;
}

.arrival-form-container .form-section.section-content-hidden{
    display: none;
}

.arrival-form-container .form-action-btn {
    padding: 10px;
    text-align: right;
}

.arrival-form-container .form-action-btn > button {
    outline: none;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    border: 2px solid var(--color-2);
    transition: box-shadow 0.2s ease;
    color: var(--bg-dark-text-color);
    background: var(--color-2);
}

.arrival-form-container .form-action-btn > button.prev-btn {
    outline: none;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    border: 2px solid var(--color-3);
    transition: box-shadow 0.2s ease;
    background: var(--bg-dark-text-color);
    color: var(--color-3);
    margin-right: 10px;
}


.arrival-form-container .form-action-btn > button:hover{
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

.arrival-form-container .confirmation-details-container{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.arrival-form-container .confirmation-details-container .each-item{
    border: 2px solid var(--color-1);
    padding: 4px 8px;
    display: flex;
    gap: 4px;
    margin: 4px;
    transition: all 0.2s ease;
}

.arrival-form-container .confirmation-details-container .each-item:hover{
    background: var(--color-1);
    color: var(--color-4);
}

.arrival-form-container .confirmation-details-container .each-item > p{
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.arrival-form-container .confirmation-details-container .each-item > p.label-tag{
    font-weight: 600;
}


/*Customized MUI Controls*/

.arrival-form-container .mui-form-control {
    padding: 10px 10px;
}

.arrival-form-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.arrival-form-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.arrival-form-container .MuiFormControlLabel-root{
    margin-top: 8px;
}

.arrival-form-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.arrival-form-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.arrival-form-container .mui-date-control .MuiFormControl-root{
    width: 100%;
}

.arrival-form-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--color-1) !important;
}

.arrival-form-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.arrival-form-container .mui-date-control .MuiButton-root{
    color: var(--color-1) !important;
}

.arrival-form-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

@media only screen and (max-width: 1000px) {

    .arrival-form-container .confirmation-details-container{
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }
    
}

@media only screen and (max-width: 800px) {

    .arrival-form-container .two-col-grid {
        grid-template-columns: auto;
    }

    .arrival-form-container .three-col-grid {
        grid-template-columns: auto;
    }

    .arrival-form-container .confirmation-details-container{
        display: grid;
        grid-template-columns: 50% 50%;
    }
    
}

@media only screen and (max-width: 500px) {

    .arrival-form-container .confirmation-details-container{
        grid-template-columns: auto;
    }
    
}