.view-profiles-component .family-profiles-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.view-profiles-component .component-header {
    position: relative;
    margin: 0px 10px 15px 10px;
    text-transform: uppercase;
}

.view-profiles-component .component-header>button.header-action-btn {
    position: absolute;
    right: 0px;
    padding: 5px 15px;
    outline: none;
    background: var(--color-1);
    color: var(--bg-dark-text-color);
    border: 2px solid var(--bg-dark-text-color);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-profiles-component .component-header>button.header-action-btn:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}

.view-profiles-component .each-profile-block {
    padding: 30px 0 40px;
    margin: 10px;
    border-radius: 0px 0px 4px 4px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-top: 2px solid var(--color-1);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
}

.view-profiles-component .each-profile-block .picture-container {
    display: inline-block;
    /* height: 130px; */
    /* width: 130px; */
    margin-bottom: 0px;
    z-index: 1;
    position: relative;
    width: 50%;
    aspect-ratio: 1;
}

.view-profiles-component .each-profile-block .picture-container::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: var(--color-1);
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
}

.view-profiles-component .each-profile-block:hover .picture-container::before {
    height: 100%;
}

.view-profiles-component .each-profile-block .picture-container::after {
    content: "";
    width: 100%;
    /* height: 100%; */
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--color-1);
    position: absolute;
    top: 1;
    left: 0;
    z-index: -1;
}

.view-profiles-component .each-profile-block .picture-container img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
}

.view-profiles-component .each-profile-block:hover .picture-container img {
    box-shadow: 0 0 0 10px var(--color-4);
    transform: scale(0.7);
}

.view-profiles-component .each-profile-block .member-content>h3,
.view-profiles-component .each-profile-block .member-content>h4 {
    display: block;
    text-transform: capitalize;
    margin: 8px;
}

.view-profiles-component .each-profile-block .action-btns {
    width: 100%;
    padding: 5px;
    margin: 0;
    /* background-color: var(--color-2); */
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
}

.view-profiles-component .each-profile-block:hover .action-btns {
    bottom: 0;
}

.view-profiles-component .each-profile-block .action-btns li {
    display: inline-block;
    margin: 0px 10px;
}

.view-profiles-component .each-profile-block .action-btns li svg {
    color: var(--color-3);
    cursor: pointer;
}


@media only screen and (max-width: 800px) {
    .view-profiles-component .family-profiles-container {
        grid-template-columns: 50% 50%;
    }

    .view-profiles-component .each-profile-block .picture-container {
        height: 100px;
        width: 100px;
    }
}