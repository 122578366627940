.shopping-item-results-tabs {
    width: calc(100% - 40px);
    padding: 10px;
    padding-top: 45px;
    display: block;
    text-align: left;
    overflow: auto;
    margin: 10px 10px;
    position: relative;
    background: #ffffff;
}


.shopping-item-results-tabs .nav-tab-header {
    position: absolute;
    top: 10px;
    left: 15px;
    right: 15px;
    text-align: left;
    color: var(--color-1);
    font-weight: bold;
    font-size: 22px;
    margin: 0;

}

.shopping-item-results-tabs .shopping-items-list{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 0px;
}

.shopping-item-results-tabs .each-menu-item {
    display: block;
    margin: 15px;
    background: var(--color-4);
    position: relative;
    cursor: pointer;
    transition: all ease 0.2s;
    border: 2px solid var(--color-1);
    border-radius: 8px;
    padding-bottom: 40px;
}

.shopping-item-results-tabs .each-menu-item:hover{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    transform: scale(105%);
    background: var(--color-1);
    color: var(--bg-dark-text-color);
}

.shopping-item-results-tabs .each-menu-item .menu-img-container {
    width: 100%;
    text-align: center;
}

.shopping-item-results-tabs .each-menu-item .menu-img-container img.menu-img {
    width: 100%;
    height: auto;
    border: 2px solid var(--bg-dark-text-color);
    border-radius: 8px 8px 0px 0px;
    aspect-ratio: 1;
    object-fit: cover;
}

.shopping-item-results-tabs .each-menu-item .menu-item-contents {
    padding: 5px 0px 10px 0px;
    text-align: left;
    position: relative;
}

.shopping-item-results-tabs .each-menu-item .menu-item-contents .menu-item-title{
    margin: 0 10px;
}

.shopping-item-results-tabs .each-menu-item .menu-item-contents .menu-item-desc{
    margin: 10px;
}

.shopping-item-results-contents .counter-container{
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 5px;
    right: 0;
}

.shopping-item-results-contents .counter-container img{
    width: 25px;
    height: 25px;
    margin: 5px 8px;
    cursor: pointer;
}

.shopping-item-results-contents .counter-container .counter-value{
    font-size: 20px;
}

.shopping-item-results-contents .counter-container span.total-price {
    width: 100%;
    text-align: right;
    padding: 0px 10px;
    font-size: 20px;
}

.shopping-item-results-tabs .action-btn-container{
    text-align: right;
    /* background: var(--color-1); */
    padding: 10px;
    border-radius: 8px;
}

.shopping-item-results-tabs .action-btn-container button{
    display: inline-block;
    width: auto;
}

.shopping-item-results-contents .item-instructions-input {
    margin: 10px;
}

.shopping-item-results-contents .each-menu-item:hover .item-instructions-input  .MuiFormLabel-root{
    color: var(--bg-dark-text-color) !important;
}

.shopping-item-results-contents .item-instructions-input  .MuiFormLabel-root{
    color: var(--text-color) !important;
}

.shopping-item-results-contents .each-menu-item:hover .item-instructions-input  .MuiInputBase-input.MuiInput-input{
    color: var(--bg-dark-text-color) !important;
}

.shopping-item-results-contents .item-instructions-input  .MuiInputBase-input.MuiInput-input{
    color: var(--text-color) !important;
}

.shopping-item-results-contents .each-menu-item .item-instructions-input  .MuiInputBase-root::before{
    border-bottom: 1px solid var(--text-color) !important;
}

.shopping-item-results-contents .each-menu-item .item-instructions-input  .MuiInputBase-root::after{
    border-bottom: 2px solid var(--bg-dark-text-color) !important;
}


.shopping-item-results-tabs .each-menu-item .item-tags{
    margin: 5px -5px 0px -5px;
}

.shopping-item-results-tabs .each-menu-item .item-tags p{
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 12px;
    background: var(--color-1);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--text-color);
    margin: 5px;
}

.shopping-item-results-tabs .each-menu-item .item-shop-time{
    margin: 5px 0px;
}

@media only screen and (min-width: 1001px) and (max-width: 1220px) {
    .shopping-item-results-tabs .shopping-items-list{
        grid-template-columns: 33.33% 33.33% 33.33%;
    }
}

@media only screen and (min-width: 701px) and (max-width: 1000px) {
    .shopping-item-results-tabs .shopping-items-list{
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 700px) {
    .shopping-item-results-tabs .shopping-items-list{
        grid-template-columns: auto;
    }
}