.single-city-trip-container {
    width: 100%;
    /* border-top: 2px solid var(--color-1); */
    /* padding: 10px; */
    /* box-shadow: 0px 20px 20px rgba(0,0,0,0.2); */
    display: grid;
    gap: 20px;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

.single-city-trip-content-column {
    padding: 15px;
    box-shadow: 0px 20px 20px rgb(0 0 0 / 20%);
    /* margin: 10px; */
    border-top: 2px solid var(--color-1);
}

.single-city-trip-content-column .form-header {
    font-size: 20px;
    margin: 0px 0px 10px 0px;
}

.single-city-trip-content-column .simple-text {
    margin: 0 10px 10px 10px;
}

.single-city-trip-content-column .family-members-list {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-bottom: 10px;
}

.single-city-trip-content-column .family-members-list .each-member {
    background: var(--bg-dark-text-color);
    position: relative;
    padding: 5px;
    display: inline-block;
    margin: 5px;
    color: var(--color-1);
    border: 2px solid var(--color-1);
    transition: all 0.2s ease;
}

.single-city-trip-content-column .family-members-list .selected-member {
    background: var(--color-1);
    color: var(--bg-dark-text-color);
}

.single-city-trip-content-column .family-members-list .member-img {
    width: 100%;
    object-fit: cover;
    background: var(--color-2);
    padding: 5px;
    border: 2px solid var(--bg-dark-text-color);
}

.single-city-trip-content-column .family-members-list .selected-member .member-img {
    background: var(--color-1);
}



.single-city-trip-content-column .family-members-list .member-name-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-city-trip-content-column .family-members-list .member-name-row>p {
    margin: 5px 5px 5px 0px;
    font-size: 14px;
}

.single-city-trip-content-column .family-members-list .member-name-row img {
    cursor: pointer;
    width: 15px;
}

/*Customized MUI Controls*/

.single-city-trip-container .mui-form-control {
    padding: 10px 10px;
}

.single-city-trip-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.single-city-trip-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.single-city-trip-container .MuiFormControlLabel-root {
    margin-top: 8px;
}

.single-city-trip-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.single-city-trip-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.single-city-trip-container .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.single-city-trip-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.single-city-trip-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.single-city-trip-container .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.single-city-trip-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

@media only screen and (max-width: 1000px) {
    .single-city-trip-content-column .family-members-list {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 800px) {
    .single-city-trip-container {
        grid-template-columns: auto;
        padding: 15px;
        border-top: 2px solid var(--color-1);
        box-shadow: 0px 20px 20px rgb(0 0 0 / 20%);
    }

    .single-city-trip-content-column {
        padding: 0px;
        box-shadow: none;
        border-top: 0px;
    }


    .single-city-trip-content-column .family-members-list {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}