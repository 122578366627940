@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Quicksand:wght@500&family=Roboto:wght@300;400;500;700&display=swap');


* {
    box-sizing: border-box;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
}

:root {
    --color-1: #568ea6;
    --color-2: #7cb6a0;
    --color-3: #e86375;
    --color-4: #ffffff;
    --color-5: #e86375;
    --text-color: #000000;
    --bg-dark-text-color: #ffffff;

    --navbar-height: 60px;
    --footer-height: 26px;
}

body,
html,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: var(--color-4);
    display: flow-root;
}

.divider {
    height: 2px;
    background: var(--color-3);
}

.hide-obj {
    visibility: hidden;
    width: 0;
    height: 0;
}

.center-align {
    text-align: center;
}

.right-align {
    text-align: right;
}

.overflow-hidden{
    overflow: hidden;
}

.pointer{
    cursor: pointer;
}

.p0{
    padding: 0 !important;
}

.m0{
    margin: 0 !important;
}

.default-btn {
    border: 2px solid var(--bg-dark-text-color);
    padding: 8px 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    color: var(--bg-dark-text-color);
    background: var(--color-1);
    cursor: pointer;
}

.slide-left {
    animation: slide_left 1s ease;
    transition: all 1s ease;
}

@keyframes slide_left {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

.slide-right {
    animation: slide_right 1s ease;
    transition: all 1s ease;
}

@keyframes slide_right {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

.slide-up {
    animation: slide_up 1s ease;
    transition: all 1s ease;
}

@keyframes slide_up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

.input-options-container{
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.input-options-container .mui-form-control {
    padding: 10px 20px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .mui-form-control {
    padding: 0px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-label {
    font-size: 14px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-root {
    margin-top: 0;
    margin-right: 0;
}

.input-options-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.input-options-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.input-options-container .MuiFormControlLabel-root {
    margin-top: 8px;
}

.input-options-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.input-options-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.input-options-container .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.input-options-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.input-options-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.input-options-container .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.input-options-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}