.restaurant-results-tabs {
    width: calc(100% - 40px);
    padding: 10px;
    padding-top: 45px;
    display: block;
    text-align: left;
    overflow: auto;
    margin: 10px 20px;
    border: 3px solid var(--color-2);
    position: relative;
}


.restaurant-results-tabs .nav-tab-header {
    position: absolute;
    top: 10px;
    left: 15px;
    right: 15px;
    text-align: left;
    color: var(--color-1);
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}

.restaurant-results-tabs .each-navigation-tab {
    width: 100px;
    display: inline-grid;
    aspect-ratio: 1;
    border: 2px solid var(--bg-dark-text-color);
    border-radius: 50%;
    color: var(--bg-dark-text-color);
    box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 50%);
    margin: 10px;
    background: var(--color-1);
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    position: relative;
}

.restaurant-results-tabs .each-navigation-tab:hover {
    background: var(--color-2);
    border-width: 4px;
    transform: translateY(-10px);
    margin-bottom: 0px;
}

.restaurant-results-tabs .each-navigation-tab img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    aspect-ratio: 1;
    object-fit: cover;
}

.restaurant-results-tabs .each-navigation-tab h3,
.restaurant-results-tabs .each-navigation-tab h4 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    align-items: center;
    margin: 0;
    padding: 20px;
}

.restaurant-results-tabs.sponsored-results-tabs .each-navigation-tab{
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.restaurant-results-tabs.sponsored-results-tabs .each-navigation-tab img, 
.restaurant-results-tabs.sponsored-results-tabs .each-navigation-tab h4{
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.restaurant-results-tabs .each-menu-item {
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);
    margin: 10px;
    border: 8px solid var(--color);
    background: var(--white-color);
    position: relative;
    cursor: pointer;
    transition: all ease 0.2s;
    border-radius: 4px;
}

.restaurant-results-tabs .each-menu-item:hover{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
    background: var(--color-1);
    color: var(--bg-dark-text-color);
}

.restaurant-results-tabs .each-menu-item .menu-img-container {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.restaurant-results-tabs .each-menu-item .menu-img-container img.menu-img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    height: auto;
    border: 2px solid var(--bg-dark-text-color);
}

.restaurant-results-tabs .each-menu-item .menu-item-contents {
    padding: 10px;
    text-align: left;
    padding-top: 5px;
    position: relative;
}

.restaurant-results-tabs .each-menu-item .menu-item-contents .menu-item-title{
    margin: 0;
}

.restaurant-results-tabs .action-btn-container{
    text-align: right;
}

.restaurant-results-tabs .action-btn-container button{
    display: inline-block;
    width: auto;
}

.restaurant-results-tabs .each-menu-item .item-tags{
    margin: 5px -5px 0px -5px;
}

.restaurant-results-tabs .each-menu-item .item-tags p{
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 12px;
    background: var(--color-1);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--bg-dark-text-color);
    margin: 5px;
}

.restaurant-results-tabs .each-menu-item .item-shop-time{
    margin: 5px 0px;
}

.restaurant-results-tabs.restaurant-types-tabs .each-navigation-tab{
    width: 150px;
    aspect-ratio: 4 / 3;
    border-radius: 10px;
}
.restaurant-results-tabs.restaurant-types-tabs .each-navigation-tab img, 
.restaurant-results-tabs.restaurant-types-tabs .each-navigation-tab h3{
    aspect-ratio: 4 / 3;
    border-radius: 10px;
    object-fit: cover;
}