.shopping-payment-container {
    width: 100%;
    padding: 10px 20px;
}

.shopping-payment-container .shopping-payment-header-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shopping-payment-container .shopping-payment-header-section .shopping-payment-header {
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}


.shopping-payment-container .shopping-payment-form-contents {
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    border-top: 2px solid var(--color-1);
    margin: 15px 0px;
    padding: 15px;
}

.shopping-payment-container .form-section {
    padding: 0px 10px;
    border: 2px solid var(--color-2);
    margin-top: 10px;
    border-radius: 4px;
}

.shopping-payment-container .shopping-payment-form-column .form-section .section-title {
    margin: 10px 0px;
    color: var(--color-2);
    font-weight: bold;
}

.shopping-payment-container .shopping-payment-form-column .two-col-grid {
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    gap: 10px;
}


.shopping-payment-container .coverage-details-table td {
    padding: 5px 15px 5px 0px;
}

.shopping-payment-container .shopping-cart-table {
    width: 100%;
    border-collapse: collapse;
    margin: 15px 0px;
}

.shopping-payment-container .shopping-cart-table th {
    background: var(--color-2);
    text-align: left;
    color: var(--bg-dark-text-color);
}

.shopping-payment-container .shopping-cart-table th,
.shopping-payment-container .shopping-cart-table td {
    padding: 5px;
}

.shopping-payment-container .shopping-cart-table tr {
    border-bottom: 2px solid var(--color-2);
}

.shopping-payment-container .shopping-cart-table tr:last-child {
    border: 0px;
}

.toggle-counter {
    display: flex;
    align-items: center;
}

.toggle-counter img {
    width: 20px;
    height: 20px;
    margin: 5px 8px;
}

.shopping-payment-container .shopping-cart-table .total-amt {
    background: var(--color-2);
    text-align: right;
    border-bottom: 2px solid var(--color-2);
    color: var(--bg-dark-text-color);
}

.shopping-payment-container .action-btn{
    margin-bottom: 10px;
}

/*Customized MUI Controls*/

.shopping-payment-container .mui-form-control {
    padding: 10px 0px;
}

.shopping-payment-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.shopping-payment-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.shopping-payment-container .MuiFormControlLabel-root {
    margin-top: 0px;
}

.shopping-payment-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.shopping-payment-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.shopping-payment-container .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.shopping-payment-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.shopping-payment-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.shopping-payment-container .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.shopping-payment-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

@media only screen and (max-width: 1000px) {
    .shopping-payment-container .shopping-payment-form-contents {
        grid-template-columns: auto;
        gap: 0;
    }
}

@media only screen and (max-width: 400px) {
    .shopping-payment-container .shopping-payment-form-column .two-col-grid {
        grid-template-columns: auto;
        gap: 0px;
    }
}