.travel-checklist-container{
    width: 100%;
    padding: 10px 20px;
}

.travel-checklist-container .travel-checklist-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.travel-checklist-container .travel-checklist-header-section .travel-checklist-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.travel-checklist-data-container{
    margin: 15px 0px;
}

.travel-checklist-content{
    border-top: 2px solid var(--color-1);
    padding: 15px;
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.checklist-column .checklist-header{
    margin-top: 0px;
    background: var(--color-3);
    padding: 5px 10px;
    color: var(--bg-dark-text-color);
    border-radius: 4px;
}

.checklist-column .checklist-items {
    /* border: 2px solid var(--text-color); */
    margin: 0px 0px 15px 0px;
    align-items: center;
}

.checklist-column .each-checklist-item:first-child{
    cursor: pointer;
}

.checklist-column .each-checklist-item{
    display: grid;
    grid-template-columns: 40px calc(100% - 40px);
    /* border: 2px solid var(--color-1); */
    transition: all 1s ease;
    font-size: 12px;
}

.checklist-column .checklist-items .img-holder{
    width: 40px;
    background: var(--color-1);
    padding: 4px;
    /* border-right: 2px solid var(--color-1); */
    display: grid;
    place-items: center;
}

.checklist-items .img-holder img{
    width: 100%;
    height: auto;
    padding: 3px;
}

.checklist-items .img-holder img.cross-icon{
    padding: 8px;
}

.checklist-items p{
    margin: 0px;
    padding: 10px;
    border: 2px solid var(--color-1);
}

.checklist-items p.member-name{
    background: var(--color-2);
    color: var(--bg-dark-text-color);
}

/*Customized MUI Controls*/

.travel-checklist-container .mui-form-control {
    padding: 10px 0px;
}

.travel-checklist-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.travel-checklist-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.travel-checklist-container .MuiFormControlLabel-root {
    margin-top: 8px;
}

.travel-checklist-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.travel-checklist-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.travel-checklist-container .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.travel-checklist-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.travel-checklist-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.travel-checklist-container .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.travel-checklist-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}


@media only screen and (max-width: 800px) {
    .travel-checklist-content{
        grid-template-columns: auto;
    }
}