.forgot-password-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: grid;
    place-items: center;
    background: rgb(46, 104, 129);
    background: linear-gradient(45deg, rgba(46, 104, 129, 1) 10%, rgba(55, 127, 158, 1) 49%, rgba(106, 166, 191, 1) 88%);
}

.forgot-password-form-container {
    width: 100%;
    max-width: 450px;
    padding: 30px;
    border-radius: 10px;
    background: var(--color-4);
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
}

.forgot-password-form-container .form-header {
    display: grid;
    place-items: center;
}

.forgot-password-form-container .header-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--color-1);
    text-align: center;
    padding: 10px;
}

.forgot-password-form-container .header-img > img{
    width: auto;
    height: 100%;
}

.forgot-password-form-container .mui-form-control{
    padding: 10px 0px;
}

.forgot-password-form-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.forgot-password-form-container .mui-form-control .MuiInputLabel-root{
    color: var(--text-color);
}

.forgot-password-form-container .mui-form-control .MuiInput-underline:after{
    border-color: var(--text-color);
}

.forgot-password-form-container .mui-form-control button.submit-btn {
    width: 100%;
    display: block;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-dark-text-color);
    background: var(--color-2);
    border: 0px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.forgot-password-form-container .mui-form-control button.submit-btn:hover{
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    transform: translateY(-5px);
}

.forgot-password-form-container .link-texts a {
    margin: 10px 0px;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-color);
    transition: all 0.1s ease;
    display: block;
}

.forgot-password-form-container .link-texts a:hover {
    text-decoration: underline;
    color: var(--color-1);
}
