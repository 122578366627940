.privacy-policies-component {
    display: grid;
    width: 100%;
}

.privacy-policies-container {
    display: flow-root;
    padding: 10px;
    color: var(--text-color);
    margin: 20px;
}

.privacy-policies-header {
    display: grid;
    margin: 10px;
}

.privacy-policies-header h1{
    margin: 10px 0px;
    text-align: center;
    color: var(--text-color);
}

.privacy-policies-header h3{
    margin: 10px 0px;
    text-align: center;
    color: var(--text-color);
}

.privacy-policies-content {
    display: flow-root;
    padding: 10px;
    font-size: 18px;
}

.privacy-policies-content a{
    color: var(--text-color)
}

.privacy-policies-content .content-subheader{
    color: var(--text-color);
}

.privacy-policies-content .privacy-policies-table-container{
    width: 100%;
    margin: 15px 0px;
    overflow-x: auto;
}

.privacy-policies-content .privacy-policies-table-container table{
    border-collapse: collapse;
}

.privacy-policies-content .privacy-policies-table-container table thead{
    background: var(--color-2);
    color: var(--bg-dark-text-color);
}

.privacy-policies-content .privacy-policies-table-container table tr td,
.privacy-policies-content .privacy-policies-table-container table tr th{
    padding: 5px;
    border: 1px solid var(--text-color);
}
