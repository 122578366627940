.health-documents-container{
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

.health-documents-container .health-documents-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.health-documents-container .health-documents-header-section .health-documents-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.health-documents-container .tab-btns{
    text-align: right;
}

.health-documents-container button.tab-btn {
    padding: 8px 15px;
    font-size: 14px;
    color: var(--color-1);
    border: 2px solid var(--color-1);
    border-radius: 30px;
    margin: 5px;
    background: var(--color-4);
    cursor: pointer;
    transform: 0.2s all ease;
}

.health-documents-container button.tab-btn:hover {
    box-shadow: 0px 5px 5px rgba(0,0,0,0.3);
}

.health-documents-container button.tab-btn.selected {
    background: var(--color-1);
    border: 2px solid var(--color-4);
    color: var(--color-4);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.3);
}

.health-documents-container .document-form-container{
    margin-top: 15px;
}