.home-component {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

.home-component .home-tabs-container {
    width: 100%;
    padding: 0px;
    display: flow-root;
}

.home-tabs-container .each-nav-items-block {
    display: flow-root;
    padding: 20px;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
    margin: 15px 5px;
    border-radius: 5px;
    border-top: 2px solid var(--color-3);
}

.home-tabs-container .each-nav-items-block>.nav-items-block-header {
    font-family: 'Quicksand', sans-serif;
    margin: 0px 0px 10px 0px;
}

.each-nav-items-block .home-tab-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 0px -10px;
}

.each-nav-items-block .home-tab-items .each-tab-item {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    background: var(--color-1);
    display: grid;
    border-radius: 10px;
    place-items: center;
    padding: 20px 10px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 2px solid var(--bg-dark-text-color);
}

.each-nav-items-block .home-tab-items .each-tab-item:hover {
    background: var(--color-2);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.3);
    transform: translateY(-5px);
}

.each-nav-items-block .home-tab-items .each-tab-item>img {
    height: 60px;
    width: auto;
    max-width: 100%;
}

.each-nav-items-block .home-tab-items .each-tab-item>h3 {
    margin: 10px 10px 0px 10px;
    color: var(--bg-dark-text-color);
    text-align: center;
}

.home-component .input-options-container{
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.home-component .mui-form-control {
    padding: 10px 0px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .mui-form-control {
    padding: 0px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-label {
    font-size: 14px;
}

.home-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-root {
    margin-top: 0;
    margin-right: 0;
}

.home-component .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.home-component .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.home-component .MuiFormControlLabel-root {
    margin-top: 8px;
}

.home-component .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.home-component .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.home-component .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.home-component .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.home-component .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.home-component .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.home-component .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

.vaccine-upload-section .MuiSvgIcon-root {
    color: var(--color-2);
}




@media only screen and (max-width: 1000px) {
    .each-nav-items-block .home-tab-items .each-tab-item>img {
        height: 40px;
    }

    .each-nav-items-block .home-tab-items .each-tab-item>h3 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 900px) {
    .each-nav-items-block .home-tab-items {
        grid-template-columns: 50% 50%;
    }

    .each-nav-items-block .home-tab-items .each-tab-item>img {
        height: 30px;
    }

    .each-nav-items-block .home-tab-items .each-tab-item>h3 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .each-nav-items-block .home-tab-items {
        grid-template-columns: auto;
    }
}