.landing-page-component {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flow-root;
}

.landing-page-component .main-screen{
    padding: 20px 0px;
}

.landing-page-content {
    min-height: calc(100% - var(--navbar-height));
    padding: 40px 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}

.landing-page-content .app-intro {
    display: grid;
}

.landing-page-content .app-intro>* {
    margin: 5px 0px;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
}

.landing-page-content .app-intro .header-1 {
    font-size: 55px;
    color: var(--color-3);
}

.landing-page-content .app-intro .header-2 {
    font-size: 40px;
    color: var(--color-2);
}

.landing-page-content .app-intro .divider {
    width: 30%;
}

.landing-page-content .app-intro .description {
    font-size: 22px;
    color: var(--text-color);
}

.landing-page-content .app-intro .intro-button button {
    cursor: pointer;
    color: var(--bg-dark-text-color);
    background: var(--color-1);
    border: 3px solid var(--bg-dark-text-color);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    margin: 20px 0px;
    padding: 5px 20px;
    font-size: 18px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.landing-page-content .app-intro .intro-button button:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}


.landing-page-content .landing-image-container {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 40px;
}

.landing-page-content .landing-image-container img.landing-image {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
}

.bg-box-clip {
    position: relative;
}

.bg-box-clip:before {
    content: "";
    position: absolute;
    top: 20px;
    right: 20%;
    background: var(--color-2);
    z-index: 1;
    transform: rotate(-45deg);
    width: 70%;
    aspect-ratio: 1;
    border-radius: 27% 73% 62% 38% / 25% 34% 66% 75%
}

.landing-page-component .video-container {
    margin: 20px 0px 0px 0px;
    background: var(--color-2);
}

.landing-page-component .video-container video {
    width: 100%;
    max-width: 500px;
    height: auto;
    border: 4px solid var(--color-4);
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.6);
}

.landing-page-component .instructions {
    padding: 0px;
    width: calc(100% - 40px);
    color: var(--bg-dark-text-color);
    position: relative;
    margin: 20px;
}

.instructions .instruction-head {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    margin: 20px 0px;
}

.instruction-head h2 {
    font-family: "Kaushan Script", cursive;
    letter-spacing: 2px;
    font-size: 30px;
}

.instruction-head img {
    width: 100%;
    max-width: 250px;
    height: auto;
    display: block;
    float: right;
}

.steps-list {
    margin: 0;
    padding: 0;
}

.steps-list li {
    margin: 25px 20px;
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .landing-page-content {
        grid-template-columns: auto;
        padding: 10px;
        place-items: center;
    }

    .landing-page-content .app-intro .header-1 {
        font-size: 45px;
    }

    .landing-page-content .app-intro .header-2 {
        font-size: 30px;
    }

    .landing-page-content .app-intro .description {
        font-size: 16px;
    }

    .landing-page-component .video-container {
        padding: 20px 0px;
    }
}