.terms-and-conditions-component {
    display: grid;
    width: 100%;
}

.terms-and-conditions-container {
    display: flow-root;
    padding: 10px;
    margin: 20px;
}

.terms-and-conditions-header {
    display: grid;
    margin: 10px;
}

.terms-and-conditions-header h1{
    margin: 10px 0px;
    text-align: center;
    color: var(--text-color);
}

.terms-and-conditions-header h3{
    margin: 10px 0px;
    text-align: center;
    color: var(--text-color);
}

.terms-and-conditions-content {
    display: flow-root;
    padding: 10px;
    font-size: 18px;
}

.terms-and-conditions-content a{
    color: var(--text-color)
}

.terms-and-conditions-content .content-subheader{
    color: var(--text-color);
}
