.identity-verification-component {
    width: 100%;
    padding: 10px 20px;
    position: relative;
}

.identity-verification-component .identity-verification-header-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.identity-verification-component .identity-verification-header-section .identity-verification-header {
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.identity-verification-component .identity-verification-contents {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
    width: 100%;
}

.identity-verification-component .identity-verification-column {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--bg-dark-text-color);
    position: relative;
    background: var(--color-1);
    transition: all 0.2s ease;
}

.identity-verification-component .identity-verification-column:hover {
    background: var(--color-2);
    transform: translateY(-5px);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
}

.identity-verification-component .identity-verification-column .column-title {
    text-align: center;
    margin-top: 0px;
}

.identity-verification-component .identity-verification-column .upload-icon-img-container {
    width: calc(100% - 40px);
    padding: 40px;
    margin: 20px;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.identity-verification-column .upload-icon-img-container img {
    width: 60%;
    height: auto;
}

.corner-borders::before,
.corner-borders::after {
    border-color: var(--bg-dark-text-color);
    border-width: 20px;
    content: "";
    display: block;
    height: 50px;
    pointer-events: none;
    position: absolute;
    width: 50px;
}

.corner-borders--left::before,
.corner-borders--left::after {
    left: 0;
}

.corner-borders--left::before {
    border-left-style: solid;
    border-top-style: solid;
    top: 0;
}

.corner-borders--left::after {
    border-bottom-style: solid;
    border-left-style: solid;
    bottom: 0;
}

.corner-borders--right::before,
.corner-borders--right::after {
    right: 0;
}

.corner-borders--right::before {
    border-right-style: solid;
    border-top-style: solid;
    top: 0;
}

.corner-borders--right::after {
    border-bottom-style: solid;
    border-right-style: solid;
    bottom: 0;
}

@media only screen and (max-width: 800px) {
    .identity-verification-component .identity-verification-contents {
        grid-template-columns: auto;
    }
}