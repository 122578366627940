.settings-component{
    width: 100%;
    height: 100%;
    padding: 20px;
}

.settings-component .settings-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-component .settings-header-section .settings-header{
    margin: 0px;
    font-size: 25px;
    padding: 8px 0px;
    color: var(--color-1);
    font-weight: 600;
}

.change-password-content{
    margin-top: 15px;
}

.change-password-content .mui-form-control {
    padding: 10px 0px;
}

.change-password-content .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.change-password-content .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.change-password-content .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.change-password-content .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.change-password-content .mui-form-control button.submit-btn {
    width: 100%;
    display: block;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-dark-text-color);
    background: var(--color-2);
    border: 0px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.change-password-content .mui-form-control button.submit-btn:hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.change-password-content {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    padding: 20px;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
    border-top: 2px solid var(--color-3);
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.change-password-content .description-section > .form-section-header {
    font-size: 30px;
    font-weight: bold;
    margin: 0px 0px 20px 0px;
}


@media only screen and (max-width: 800px){
    .change-password-content {
        grid-template-columns: auto;
    }
}