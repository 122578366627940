.vaccination-proof-container{
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

.vaccination-proof-container .vaccination-proof-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vaccination-proof-container .vaccination-proof-header-section .vaccination-proof-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.evisa-vaccine-upload-form-container {
    margin-top: 15px;
    width: 100%;
    border-top: 2px solid var(--color-1);
    padding: 15px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
}

.evisa-vaccine-upload-form-container .form-header {
    margin-top: 0px;
    font-size: 20px;
    font-weight: bold;
}

.evisa-vaccine-upload-form-content {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.evisa-vaccine-upload-form-content .two-col-grid {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.evisa-vaccine-upload-form-content .two-col-grid.vacine-type-checkbox-input {
    grid-template-columns: 50% 50%;
    gap: 0px;
}

.evisa-vaccine-upload-form-content .checkbox-section-header {
    margin: 10px 0px;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data {
    width: 100%;
    overflow-y: auto;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data {
    padding: 10px;
    border: 2px solid var(--color-1);
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 15px;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table thead {
    border-bottom: 2px solid var(--bg-dark-text-color);
    text-align: left;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table tr {
    background: var(--color-2);
    color: var(--bg-dark-text-color);
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table tbody tr:nth-child(odd) {
    background: var(--color-1);
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table tr td,
.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data table.vaccination-data-table tr th {
    padding: 5px;
    min-width: 100px;
    font-size: 14px;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data .icons-cell {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
}

.evisa-vaccine-upload-form-content .evisa-vaccine-uploaded-data .icons-cell img {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    cursor: pointer;
}




/*Customized MUI Controls*/

.evisa-vaccine-upload-form-container .mui-form-control {
    padding: 10px 0px;
}

.evisa-vaccine-upload-form-content .two-col-grid.vacine-type-checkbox-input .mui-form-control {
    padding: 0px;
}

.evisa-vaccine-upload-form-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-label {
    font-size: 14px;
}

.evisa-vaccine-upload-form-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-root {
    margin-top: 0;
    margin-right: 0;
}

.evisa-vaccine-upload-form-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.evisa-vaccine-upload-form-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.evisa-vaccine-upload-form-container .MuiFormControlLabel-root {
    margin-top: 8px;
}

.evisa-vaccine-upload-form-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.evisa-vaccine-upload-form-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.evisa-vaccine-upload-form-container .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.evisa-vaccine-upload-form-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.evisa-vaccine-upload-form-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.evisa-vaccine-upload-form-container .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.evisa-vaccine-upload-form-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

.evisa-vaccine-upload-section .MuiSvgIcon-root {
    color: var(--color-2);
}


@media only screen and (max-width: 1000px) {

    .evisa-vaccine-upload-form-content {
        grid-template-columns: auto;
    }
}


@media only screen and (max-width: 400px) {
    .evisa-vaccine-upload-form-content .two-col-grid {
        grid-template-columns: auto;
    }

    .evisa-vaccine-upload-form-content .two-col-grid.vacine-type-checkbox-input {
        grid-template-columns: auto;
    }
}