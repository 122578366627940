.dashboard-component{
    width: 100%;
    height: 100%;
}

.dashboard-container {
    width: 100%;
    padding-right: 300px;
    overflow: auto;
    height: calc(100% - var(--navbar-height));
}

.advertisement-banner{
    width: 300px;
    height: calc(100% - var(--navbar-height) - var(--footer-height));
    background: transparent;
    position: absolute;
    overflow: auto;
    top: var(--navbar-height);
    right: 12px;
    bottom: 0;
}

.advertisement-banner .advertisement-image{
    display: block;
    width: calc(100% - 20px);
    height: auto;
    margin: 10px;
}

.advertisement-banner::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 800px) {
    .advertisement-banner{
        width: 250px;
    }

    .dashboard-container {
        padding-right: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .advertisement-banner{
        display: none;
    }

    .dashboard-container {
        padding-right: 0px;
    }
}