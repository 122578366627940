.airport-map-info-component {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    position: relative;
    /* display: grid;
    place-items: center; */
}

.airport-map-info-component .airport-map-info-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.airport-map-info-component .airport-map-info-header-section .airport-map-info-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.airport-map-info-component .airport-map-info-component-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--color-1);
    padding: 20px 20px 10px 20px;
}

.airport-map-info-component .airport-map-info-component-header h2 {
    margin: 10px 0px;
}

.airport-map-info-component .header-tab-btns button {
    margin-left: 20px;
    outline: none;
    border: 2px solid var(--text-color);
    color: var(--text-color);
    cursor: pointer;
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 50%);
    background: var(--color-2);
    font-size: 20px;
    padding: 8px 10px;
}

.airport-map-info-component .header-tab-btns button:first-child {
    background: var(--color-2);
}

.airport-map-info-component .header-tab-btns button:last-child {
    background: var(--color-1);
}

.airport-map-info-component .airport-map-info-contents {
    display: grid;
    /* grid-template-columns: 65% 35%; */
    width: 100%;
    padding: 0px 0px 10px 0px;
}

.airport-map-info-component .airport-map-info-contents.grid-two-col {
    grid-template-columns: 70% 30%;
}

.airport-map-info-component .airport-map-info-contents.grid-two-col>* {
    height: fit-content;
}

.airport-map-info-component .airport-map-info-column {
    /* padding: 20px; */
    margin: 10px 0px;
    /* box-shadow: 0px 10px 30px rgb(0 0 0 / 50%); */
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    /* border-radius: 8px; */
}

.airport-map-info-component .airport-map-info-column .column-title {
    text-align: left;
    margin-top: 0px;
}

.airport-map-info-component .location-details {
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.5);
    border: 2px solid var(--text-color);
    color: var(--text-color);
}

.airport-map-info-contents .map-container {
    width: 100%;
    height: auto;
    position: relative;
}

.airport-map-info-contents .map-container .map-img {
    width: 100%;
    height: auto;
}

.airport-map-info-contents .map-container .map-marker {
    position: absolute;
}

.airport-map-info-contents .map-container .helper-text {
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    margin: 10px;
    font-size: 13px;
}


.airport-map-info-component .location-details .timing-chart .each-day,
.airport-map-info-component .location-details .place-options {
    display: grid;
    grid-template-columns: 80px calc(100% - 80px);
}

.airport-map-info-component .location-details .timing-chart .each-day p {
    margin: 0px;
    padding: 8px 0px;
}

.airport-map-info-component .location-details .place-options {
    margin-top: 20px;
}

.airport-map-info-component .shop-details-container {
    margin: 10px 20px;
}

.airport-map-info-component .shopping-details-popup {
    padding: 20px;
    margin: 0px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: grid;
    place-items: end;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.airport-map-info-component .shop-details-container-side {
    margin: 10px 20px 10px 0px;
}

.airport-map-info-component .shop-details-container .each-menu-item {
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);
    margin: 0px 0px;
    background: var(--color-4);
    position: relative;
    cursor: pointer;
    border: 2px solid var(--color-1);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    transition: all ease 0.2s;
}

.airport-map-info-component .shop-details-container-side .each-menu-item {
    grid-template-columns: auto;
}

.airport-map-info-component .shop-details-container .each-menu-item:hover {
    transform: translateY(-10px);
    background: var(--color-1);
    color: var(--text-color);
    border: 2px solid var(--bg-dark-text-color);

}

.airport-map-info-component .shop-details-container .each-menu-item .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 1;
    background: var(--color-1);
    border-radius: 50%;
}

.airport-map-info-component .shop-details-container .each-menu-item .menu-img-container {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.airport-map-info-component .shop-details-container .each-menu-item .menu-img-container img.menu-img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    height: auto;
    border: 2px solid var(--bg-dark-text-color);
}

.airport-map-info-component .shop-details-container .each-menu-item .menu-item-contents {
    padding: 10px;
    text-align: left;
    padding-top: 5px;
    position: relative;
}

.airport-map-info-component .shop-details-container .each-menu-item .menu-item-contents .menu-item-title {
    margin: 0;
}

.airport-map-info-component .shop-details-container .each-menu-item .item-tags {
    margin: 5px -5px 0px -5px;
}

.airport-map-info-component .shop-details-container .each-menu-item .item-tags p {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 12px;
    background: var(--color-1);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--bg-dark-text-color);
    margin: 5px;
}

.airport-map-info-component .shop-details-container .each-menu-item .item-shop-time {
    margin: 5px 0px;
}

.airport-map-info-component .item-search-results-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0px;
}