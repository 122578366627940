.health-survey-component{
    width: 100%;
    border-top: 2px solid var(--color-1);
    padding: 15px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    position: relative;
}

.health-survey-component .health-survey-contents{
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.health-survey-component  .health-survey-column {
    position: relative;
}


.health-survey-component .health-survey-column .column-title{
    margin-top: 0px;
    font-size: 20px;
    font-weight: bold;
}


.health-survey-component .health-survey-column .question-items-list {
    display: grid;
    padding: 0;
    margin: 0px 20px;
    grid-template-columns: 50% 50%;
}

.health-survey-component .health-survey-column .survey-questions .each-question > p{
    font-weight: 600;
}

.health-survey-component .health-survey-column .survey-questions p{
    margin: 0px;
    padding: 6px 6px 6px 0px;
}

.survey-questions{
    display: flow-root;
}

.health-survey-component .health-survey-column .option-btn-row .yes-btn, 
.health-survey-component .health-survey-column .option-btn-row .no-btn{
    border: 2px solid transparent;
    padding: 8px 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    color: var(--color-1);
    cursor: pointer;
    background: transparent;
    margin: 10px 0px;
    margin-left: 20px;
    position: relative;
    transition: all 0.1s ease;
    border-radius: 4px;
}

/* .health-survey-component .health-survey-column .option-btn-row button.selected::after{
    content: '';
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background: transparent;
    position: absolute;
    top: -8px;
    left: -8px;
    border: 3px solid var(--font-color);
    border-radius: 4px;
} */

.health-survey-component .health-survey-column .option-btn-row .yes-btn.selected{
    background: var(--color-2);
    border: 2px solid var(--color-4);
    color: var(--color-4);
}

.health-survey-component .health-survey-column .option-btn-row .no-btn.selected{
    background: var(--color-5);
    border: 2px solid var(--color-4);
    color: var(--color-4);
}

.health-survey-contents .health-survey-column .submit-btn{
    border: 2px solid var(--color-4);
    background: var(--color-1);
    padding: 8px 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    color: var(--color-4);
    cursor: pointer;
    margin-top: 15px;
}
