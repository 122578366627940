.help-chat-component {
    padding: 20px;
    color: var(--bg-dark-text-color);
    position: absolute;
    inset: 0;
    background: var(--color-1);
}


.help-chat-component .help-chat-title{
    margin-top: 0px;
}

.help-chat-component .close-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.help-chat-component .chat-bot-icon-container{
    padding: 10px;
    border: 2px solid var(--bg-dark-text-color);
    background: var(--color-2);
    position: absolute;
    display: inline-block;
    left: -20px;
    bottom: -20px;
    border-radius: 50%;
}