.plan-excursions-component {
    width: 100%;
    display: grid;
    place-items: center;
    position: relative;
    padding: 10px 20px;
}

.plan-excursions-component .plan-excursions-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plan-excursions-component .plan-excursions-header-section .plan-excursions-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}


.plan-excursions-navigation-tabs {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    place-items: center;
    overflow: auto;
    height: 100%;
}

.plan-excursions-navigation-tabs .each-navigation-tab {
    max-width: 300px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    border: 2px solid;
    align-items: center;
    justify-content: center;
    color: var(--bg-dark-text-color);
    box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 50%);
    padding: 20px;
    margin: 20px;
    background: var(--color-1);
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
}

.plan-excursions-navigation-tabs .each-navigation-tab:hover {
    transform: translateY(-10px);
    background: var(--color-2);
    border-width: 4px;
    /* box-shadow: 0px 0px 20px 8px rgb(0 0 0 / 50%); */
}

.plan-excursions-navigation-tabs .each-navigation-tab img {
    height: 80px;
    width: auto;
    transition: all 0.5s ease;
}

.plan-excursions-navigation-tabs .each-navigation-tab h2 {
    margin: 10px;
}

@media only screen and (min-width: 1221px) and (max-width: 1500px) {
    .plan-excursions-navigation-tabs {
        grid-template-columns: 25% 25% 25% 25%;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1220px) {
    .plan-excursions-navigation-tabs {
        grid-template-columns: 33.33% 33.33% 33.33%;
    }
}

@media only screen and (min-width: 701px) and (max-width: 1000px) {
    .plan-excursions-navigation-tabs {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 700px) {
    .plan-excursions-navigation-tabs {
        grid-template-columns: auto;
    }
}
