.footer-component {
    width: 100%;
    height: 100%;
    display: flow-root;
    background: var(--color-2);
    padding: 4px 10px;
    text-align: right;
}

.footer-component p {
    margin: 0px;
    font-size: 14px;
    color: var(--bg-dark-text-color);
}
