.government-approval-component {
    width: 100%;
    padding: 10px 20px;
    position: relative;
}

.government-approval-component .government-approval-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.government-approval-component .government-approval-header-section .government-approval-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}

.government-approval-component .government-approval-contents {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    width: 100%;
    gap: 20px;
    margin-top: 10px;
}

.government-approval-component  .government-approval-column {
    padding: 15px;
    width: 100%;
    box-shadow: 0 20px 20px rgba(0,0,0,0.2);
    border-top: 2px solid var(--color-1);
    color: var(--text-color);
    position: relative;
    background: var(--color-4);
}


.government-approval-component .government-approval-column .column-title{
    text-align: center;
    margin-top: 0px;
}

.government-approval-component .government-approval-column .form-input-row{
    margin: 10px 0px;
}


.government-approval-component .approval-data-list{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.government-approval-component .approval-data-list li{
    display: grid;
    grid-template-columns: calc(100% - 55px) 40px;
    grid-gap: 15px;
    align-items: center;
}

.government-approval-component .approval-data-list li img{
    cursor: pointer;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
    background: var(--color-1);
    padding: 4px;
    object-fit: contain;
}

.government-approval-component .approval-data-list li p{
    background: var(--color-2);
    border: 2px solid var(--color-2);
    padding: 10px;
    margin: 5px;
    color: var(--bg-dark-text-color);
}

.government-approval-component .approval-data-list li p.danger{
    background: var(--color-5);
    border: 2px solid var(--color-5);
}


.government-approval-contents .two-col-row {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    align-items: center;
    grid-gap: 20px;
}
.government-approval-contents .two-col-row > * {
    margin: 15px 0px;
    width: calc(100% - 5px);
}

.government-approval-contents .grid-image-container{
    width: 100%;
    aspect-ratio: 1;
    background: #fff;
}

.government-approval-contents .grid-image-container img{
    width: 100%;
    height: 100%;
}

.government-approval-contents .user-approval-details{
    display: flow-root;
}

.government-approval-contents .user-approval-details p{
    margin: 10px 0px;
    font-size: 20px;
}

.government-approval-component .content-container{
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
    color: var(--bg-dark-text-color);
    z-index: 100;
}

.government-approval-component .content-container .img-container{
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    display: grid;
    place-items: center;
}

.government-approval-component .img-container .content-img{
    width: 100%;
    height: auto;
}

.government-approval-component .content-container .close-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
    background-color: var(--color-3);
}

/*Customized MUI Controls*/

.government-approval-component .mui-form-control {
    padding: 10px 0px;
}

.government-approval-content .two-col-grid.vacine-type-checkbox-input .mui-form-control {
    padding: 0px;
}

.government-approval-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-label {
    font-size: 14px;
}

.government-approval-content .two-col-grid.vacine-type-checkbox-input .MuiFormControlLabel-root {
    margin-top: 0;
    margin-right: 0;
}

.government-approval-component .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.government-approval-component .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.government-approval-component .MuiFormControlLabel-root {
    margin-top: 8px;
}

.government-approval-component .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.government-approval-component .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.government-approval-component .mui-date-control .MuiFormControl-root {
    width: 100%;
}

.government-approval-component .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-1) !important;
}

.government-approval-component .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.government-approval-component .mui-date-control .MuiButton-root {
    color: var(--color-1) !important;
}

.government-approval-component .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

.vaccine-upload-section .MuiSvgIcon-root {
    color: var(--color-2);
}



@media only screen and (max-width: 1000px) {
    .government-approval-component .government-approval-contents {
        grid-template-columns: auto;
    }
}