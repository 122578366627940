.departure-form-container {
    width: 100%;
    display: flow-root;
}

.departure-form-container .form-header{
    margin: 10px 0px;
    font-size: 20px;
    font-weight: bolder;
    display: flow-root;
    padding: 10px;
    background: var(--color-3);
    border-radius: 4px;
}

.departure-form-container .form-header p{
    margin: 0px;
    color: var(--bg-dark-text-color)
}

.departure-form-container .two-col-grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.departure-form-container .query-section {
    border: 2px solid var(--color-1);
    margin: 10px;
    display: flow-root;
    border-radius: 4px;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.3);
}

.departure-form-container .query-section .query-section-header{
    background: var(--color-2);
    color: var(--bg-dark-text-color);
    margin: 10px;
    padding: 8px;
    border-radius: 4px;
}

.departure-form-container .rating-item {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.departure-form-container .rating-item span.item-text{
    font-size: 14px;
}

.departure-form-container .option-btns{
    padding: 0px 5px 10px 5px;
    display: grid;
    grid-template-columns: 50% 50%;
}


.departure-form-container .option-btns button {
    font-size: 12px;
    padding: 4px 8px;
    background: transparent;
    border: 2px solid var(--color-1);
    outline: none;
    width: calc(100% - 10px);
    margin: 5px;
    cursor: pointer;
}

.departure-form-container .option-btns button.selected{
    background: var(--color-1);
    color: var(--bg-dark-text-color)
}

/*Customized MUI Controls*/

.departure-form-container .mui-form-control {
    padding: 10px 10px;
    font-size: 14px;
}

.departure-form-container .query-section .mui-form-control{
    padding: 0px 10px;
} 

.departure-form-container .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.departure-form-container .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.departure-form-container .MuiFormControlLabel-root{
    margin-top: 8px;
}

.departure-form-container .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.departure-form-container .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.departure-form-container .mui-date-control .MuiFormControl-root{
    width: 100%;
}

.departure-form-container .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--color-1) !important;
}

.departure-form-container .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.departure-form-container .mui-date-control .MuiButton-root{
    color: var(--color-1) !important;
}

.departure-form-container .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

.departure-form-container .MuiFormControlLabel-label{
    font-size: 12px;
}


@media only screen and (max-width: 800px) {

    .departure-form-container .two-col-grid {
        grid-template-columns: auto;
    }

    .departure-form-container .three-col-grid {
        grid-template-columns: auto;
    }
    
}
