.add-profile-component {
    width: 100%;
    height: 100%;
}

.add-profile-component .add-profile-container {
    width: 100%;
    padding: 20px;
    border-top: 3px solid var(--color-1);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
}

.add-profile-component .stepper-container {
    width: 100%;
    margin: 20px 0px;
    overflow-y: auto;
}

.add-profile-component .stepper-container::-webkit-scrollbar {
    display: none;
}

.add-profile-component .add-profile-form svg.Mui-completed,
.add-profile-component .add-profile-form svg.Mui-active {
    color: var(--color-3) !important;
}

.add-profile-component .stepper-sections {
    width: 100%;
    border: 1px solid var(--color-1);
    border-radius: 4px;
    padding: 10px;
    display: grid;
    grid-template-columns: auto;
}

.add-profile-component .two-col-grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.add-profile-component .stepper-sections .mui-form-control {
    padding: 10px 10px;
}

.add-profile-component .stepper-sections .mui-form-control .MuiFormHelperText-root {
    color: #ae0000;
}

.add-profile-component .stepper-sections .mui-form-control .Mui-checked {
    color: var(--color-2);
}

.add-profile-component .stepper-sections .mui-form-control .MuiInputLabel-root {
    color: var(--text-color);
}

.add-profile-component .stepper-sections .mui-form-control .MuiInput-underline:after {
    border-color: var(--text-color);
}

.add-profile-component .stepper-buttons {
    margin: 20px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
}

.add-profile-component .stepper-buttons button {
    outline: none;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
    border: 2px solid var(--color-2);
    transition: box-shadow 0.2s ease;
}

.add-profile-component .stepper-buttons .prev-btn {
    background: var(--bg-dark-text-color);
    color: var(--color-2);
}

.add-profile-component .stepper-buttons .next-btn {
    color: var(--bg-dark-text-color);
    background: var(--color-2);
}

.add-profile-component .stepper-buttons button:hover{
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}


.add-profile-component .mui-date-control .MuiFormControl-root{
    width: 100%;
}

.add-profile-component .mui-date-control .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--color-1) !important;
}

.add-profile-component .mui-date-control .Mui-selected {
    background-color: var(--color-1) !important;
}

.add-profile-component .mui-date-control .MuiButton-root{
    color: var(--color-1) !important;
}

.add-profile-component .mui-date-control .MuiInputLabel-root {
    background: var(--color-4);
    padding-right: 5px;
}

.add-profile-component .stepper-sections .profile-pic-container {
    padding: 20px;
    display: grid;
    place-items: center;
    position: relative;
}

.add-profile-component .stepper-sections .profile-pic-container > img.profile-img{
    height: 250px;
    width: 250px;
    padding: 20px;
    aspect-ratio: 1;
    object-fit: contain;
    background: var(--color-1);
    border-radius: 10px;
    cursor: pointer;
}

.add-profile-component .stepper-sections .profile-pic-container > img.upload-icon-img{
    width: 250px;
    height: 250px;
    aspect-ratio: 1;
    background: rgba(0,0,0,0.2);
    padding: 80px;
    border-radius: 10px;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    cursor: pointer;
    transition: all 0.2s ease;
}

.add-profile-component .p-abs{
    position: absolute;
}

.add-profile-component .stepper-sections .profile-pic-container:hover img.upload-icon-img{
    opacity: 1;
}

.add-profile-component .confirmation-content {
    display: grid;
    grid-template-columns: 300px calc(100% - 300px);
    place-items: center;
}

.add-profile-component .confirmation-content .profile-img{
    width: 200px;
    height: 200px;
    margin: 25px;
    border-radius: 10px;
    background: var(--color-1);
    object-fit: contain;
    padding: 10px;
}

.add-profile-component .confirmation-content .details {
    padding: 10px 10px 10px 20px;
    width: 100%;
}

.add-profile-component .confirmation-content .details table {
    width: 100%;
}

.add-profile-component .confirmation-content .details > div{
    margin: 8px 5px;
    box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
    border: 1px solid var(--color-1);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 30% 60%;
    transition: all 0.2s ease-in;
    cursor: pointer;
}

.add-profile-component .confirmation-content .details > div:hover {
    transform: translateY(-2px);
    background: var(--color-1);
    color: var(--bg-dark-text-color);
}

.add-profile-component .confirmation-content .details p{
    padding: 10px;
    font-size: 14px;
    overflow-wrap: break-word;
    margin: 0px;
}

.add-profile-component .confirmation-content .details p.field-label{
    font-weight: 600;
}



@media only screen and (max-width: 1000px) {
    .add-profile-component .two-col-grid,
    .add-profile-component .confirmation-content {
        grid-template-columns: auto;
    }

    .add-profile-component .confirmation-content .profile-img{
        width: 150px;
        height: 150px;
        padding: 5px;
        margin: 10px;
    }

    .add-profile-component .confirmation-content .details {
        padding: 10px;
        width: 100%;
    }

    .add-profile-component .confirmation-content .details > div{
        margin: 8px 0px;
    }

    .add-profile-component .stepper-sections .profile-pic-container > img.upload-icon-img,
    .add-profile-component .stepper-sections .profile-pic-container > img.profile-img{
        max-width: 150px;
        width: 100%;
        height: auto;
    }

    .add-profile-component .stepper-sections .profile-pic-container > img.upload-icon-img{
        padding: 40px;
    }
}