.app-component{
    width: 100%;
    height: calc(100% - var(--footer-height));
}

.footer-container{
    width: 100%;
    height: var(--footer-height);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
}