.help-component {
    width: 100%;
    padding: 10px 20px;
    position: relative;
}

.help-component .help-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help-component .help-header-section .help-header{
    margin: 0px;
    padding: 8px 0px;
    font-size: 25px;
    color: var(--color-1);
    font-weight: 600;
}


.help-component .help-component-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--theme-color-1);
    padding: 0px 40px;
}

.help-component .help-contents {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    width: 100%;
    gap: 20px;
    margin-top: 10px;
}

.help-component  .help-column {
    padding: 20px;
    width: 100%;
    box-shadow: 0px 20px 20px rgba(0,0,0,0.2);
    border: 2px solid var(--bg-dark-text-color);
    color: var(--bg-dark-text-color);
    position: relative;
    background: var(--color-1);
}


.help-component .help-column .column-title{
    text-align: center;
    margin-top: 0px;
}


.help-component .tutorial-items-list,
.help-component .faqs-items-list{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.help-component .tutorial-items-list li{
    display: grid;
    grid-template-columns: calc(100% - 65px) 50px;
    grid-gap: 15px;
    align-items: center;
}

.help-component .tutorial-items-list li p{
    background: var(--theme-color-2);
    border-bottom: 2px solid var(--bg-dark-text-color);
    padding: 10px;
    margin: 10px 0px;
}

.help-component .faqs-items-list li{
    display: flow-root;
}

.help-component .faqs-items-list li p{
    background: var(--theme-color-1);
    border-bottom: 2px solid var(--bg-dark-text-color);
    padding: 10px;
    margin: 10px 0px;
}

.help-component .tutorial-items-list li img {
    width: 100%;
}