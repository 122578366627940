.dine-and-shop-component {
    width: 100%;
    position: relative;
}

.dine-and-shop-component .dine-and-shop-component-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--color-1);
    padding: 0px 20px;
}

.dine-and-shop-component .dine-and-shop-contents {
    display: grid;
    width: 100%;
    padding: 0px;
}

.dine-and-shop-options-container{
    display: grid;
    grid-template-columns: 50% calc(50% - 60px) 60px;
    padding: 0px 20px;
}

.dine-and-shop-options-container .option-btns button {
    outline: none;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 20px;
    border: 2px solid var(--color-1);
    color: var(--color-1);
    cursor: pointer;
    border-radius: 30px;
    font-size: 16px;
    padding: 8px 15px;
    transition: all 0.2s ease;
}

.dine-and-shop-options-container .option-btns button:last-child{
    margin-right: 0px;
}

.dine-and-shop-options-container .option-btns button.selected{
    background: var(--color-1);
    border-color: var(--bg-dark-text-color);
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 50%);
    color: var(--bg-dark-text-color);
}

.dine-and-shop-options-container .terminal-select .custom-user-select{
    background: var(--color-1);
    color: var(--bg-dark-text-color);
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 50%);
}

.dine-and-shop-options-container .airport-info-icon{
    width: 40px;
    height: 43px;
    margin: 0px 10px;
    padding: 5px;
    background: var(--color-2);
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 50%);
    border: 2px solid var(--text-color);
    cursor: pointer;
}

.dine-and-shop-options-container .airport-info-icon img{
    width: 100%;
    height: 100%;
}

.dine-and-shop-tabs {
    width: calc(100% - 40px);
    padding: 10px;
    padding-top: 45px;
    display: block;
    text-align: left;
    overflow: auto;
    margin: 10px 20px;
    border: 3px solid var(--color-2);
    position: relative;
}


.dine-and-shop-tabs .nav-tab-header {
    position: absolute;
    top: 10px;
    left: 15px;
    right: 15px;
    text-align: left;
    color: var(--color-1);
    font-weight: bold;
    font-size: 22px;
    margin: 0;
    text-transform: uppercase;
}

.dine-and-shop-tabs .each-navigation-tab {
    width: 100px;
    display: inline-grid;
    aspect-ratio: 1;
    border: 2px solid var(--bg-dark-text-color);
    border-radius: 50%;
    color: var(--bg-dark-text-color);
    box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 50%);
    margin: 10px;
    background: var(--color-1);
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    position: relative;
}

.dine-and-shop-tabs .each-navigation-tab:hover {
    background: var(--color-2);
    border-width: 4px;
    transform: translateY(-10px);
    margin-bottom: 0px;
}

.dine-and-shop-tabs .each-navigation-tab img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 50%;
}

.dine-and-shop-tabs .each-navigation-tab h2,
.dine-and-shop-tabs .each-navigation-tab h3 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    align-items: center;
    margin: 0;
    padding: 20px;
}

.dine-and-shop-tabs.my-favorites-tabs .each-navigation-tab{
    width: 100px;
    height: 100px;
}

.dine-and-shop-tabs.store-categories-tabs .each-navigation-tab{
    width: 150px;
}

.dine-and-shop-tabs.restaurant-types-tabs .each-navigation-tab{
    width: 150px;
    aspect-ratio: 4 / 3;
    border-radius: 10px;
}
.dine-and-shop-tabs.restaurant-types-tabs .each-navigation-tab img, 
.dine-and-shop-tabs.restaurant-types-tabs .each-navigation-tab h3{
    aspect-ratio: 4 / 3;
    border-radius: 10px;
    object-fit: cover;
}

