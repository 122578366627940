.all-vaccines-list-component{
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
    color: var(--bg-dark-text-color);
    z-index: 100;
}

.all-vaccines-list-content{
    background: var(--color-4);
    border: 2px solid var(--color-1);
    padding: 20px;
    position: relative;
    min-width: 50%;
    max-width: 80%;
}

.vaccine-taken-img-container{
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 20px;
    display: grid;
    place-items: center;
}

.vaccine-taken-img-container .vaccination-img{
    width: 100%;
    height: auto;
}

.vaccine-taken-img-container .close-icon,
.all-vaccines-list-content .close-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
    background-color: var(--color-3);
}

.vaccine-taken-img-container .close-icon{
    background: #000000;
    padding: 3px;
}

.all-vaccines-list-content h3{
    text-align: center;
    padding: 5px 40px;
    margin: 0px;
    color: var(--color-1);
}

.all-vaccines-list-content .vaccines-list{
    margin: 10px 0px 0px 0px;
    padding: 10px 0;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-gap: 20px;
    list-style-type: none;
}

.all-vaccines-list-content .vaccines-list li p{
    border: 2px solid var(--bg-dark-text-color);
    padding: 8px 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    color: var(--bg-dark-text-color);
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: var(--color-1);
    margin: 0;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.all-vaccines-list-content .vaccines-list li p:hover{
    background: var(--color-2);
    transform: translateY(-4px);
}

@media only screen and (min-width: 600px) {
    .vaccine-taken-img-container .vaccination-img{
        max-width: 80%;
        height: auto;
    }
    
}